import React, { useState, useEffect } from "react";
import * as XLSX from "xlsx";
import { Button } from "antd";
import { useLazyQuery } from "@apollo/client";
import '../screens/manual-upload/styles/HeaderUploadScreen.css'
import {
  fetchUploadDataTableControllerOrdersTable,
  fetchUploadDataTableControllerOrdersLine,
  packages,
  sectors,
  customersSuppliers,
  companies,
  distributionCenters,
  addressBook,
} from "../../queries/uploadData";

export const ExcelGenerator = (props) => {
  const [
    getUloadDataTableControllerOrdersTable,
    dataUploadDataTableControllerOrdersTable,
  ] = useLazyQuery(fetchUploadDataTableControllerOrdersTable, {
    fetchPolicy: "no-cache",
  });
  const [
    getUploadDataTableControllerOrdersLine,
    dataUploadDataTableControllerOrdersLine,
  ] = useLazyQuery(fetchUploadDataTableControllerOrdersLine, {
    fetchPolicy: "no-cache",
  });
  const [getpackages, datapackages] = useLazyQuery(packages, {
    fetchPolicy: "no-cache",
  });
  const [getsectors, datasectors] = useLazyQuery(sectors, {
    fetchPolicy: "no-cache",
  });
  const [getcustomersSuppliers, datacustomersSuppliers] = useLazyQuery(
    customersSuppliers,
    { fetchPolicy: "no-cache" }
  );
  const [getcompanies, datacompanies] = useLazyQuery(companies, {
    fetchPolicy: "no-cache",
  });
  const [getdistributionCenters, datadistributionCenters] = useLazyQuery(
    distributionCenters,
    { fetchPolicy: "no-cache" }
  );
  const [getaddressBook, dataaddressBook] = useLazyQuery(addressBook, {
    fetchPolicy: "no-cache",
  });
  const [ordersTableExcel, setOrdersTableExcel] = useState([]);
  const [ordersTableExcelLine, setOrdersTableExcelLine] = useState([]);
  useEffect(() => {
    getUloadDataTableControllerOrdersTable();
    getUploadDataTableControllerOrdersLine();
    getpackages();
    getsectors();
    getcustomersSuppliers();
    getcompanies();
    getdistributionCenters();
    getaddressBook();
  }, []);
  useEffect(() => {
    if (
      !dataUploadDataTableControllerOrdersTable.loading &&
      dataUploadDataTableControllerOrdersTable.data &&
      dataUploadDataTableControllerOrdersTable.data.uploadDataTableController
        .length > 0
    ) {
      setOrdersTableExcel({
        id: dataUploadDataTableControllerOrdersTable.data
          .uploadDataTableController[0].id,
        tableName:
          dataUploadDataTableControllerOrdersTable.data
            .uploadDataTableController[0].tableName,
        uploadDataFields:
          dataUploadDataTableControllerOrdersTable.data
            .uploadDataTableController[0].uploadDataFields,
      });
    }
  }, [
    dataUploadDataTableControllerOrdersTable.loading,
    dataUploadDataTableControllerOrdersTable.data,
  ]);
  useEffect(() => {
    if (
      !dataUploadDataTableControllerOrdersLine.loading &&
      dataUploadDataTableControllerOrdersLine.data &&
      dataUploadDataTableControllerOrdersLine.data.uploadDataTableController
        .length > 0
    ) {
      setOrdersTableExcelLine({
        id: dataUploadDataTableControllerOrdersLine.data
          .uploadDataTableController[0].id,
        tableName:
          dataUploadDataTableControllerOrdersLine.data
            .uploadDataTableController[0].tableName,
        uploadDataFields:
          dataUploadDataTableControllerOrdersLine.data
            .uploadDataTableController[0].uploadDataFields,
      });
    }
  }, [
    dataUploadDataTableControllerOrdersLine.loading,
    dataUploadDataTableControllerOrdersLine.data,
  ]);

  const generateExcelHeader = () => {
    const data = [
      {
        [ordersTableExcel.uploadDataFields[40]?.fieldName]: "",
        [ordersTableExcel.uploadDataFields[0]?.fieldName]: "",
        [ordersTableExcel.uploadDataFields[33]?.fieldName]: "",
        [ordersTableExcel.uploadDataFields[34]?.fieldName]: "",
        [ordersTableExcel.uploadDataFields[35]?.fieldName]: "",
        [ordersTableExcel.uploadDataFields[36]?.fieldName]: "",
        [ordersTableExcel.uploadDataFields[37]?.fieldName]: "",
        [ordersTableExcel.uploadDataFields[38]?.fieldName]: "",
        [ordersTableExcel.uploadDataFields[15]?.fieldName]: "",
        [ordersTableExcel.uploadDataFields[4]?.fieldName]: "",
        [ordersTableExcel.uploadDataFields[5]?.fieldName]: "",
        [ordersTableExcel.uploadDataFields[7]?.fieldName]: "",
        [ordersTableExcel.uploadDataFields[6]?.fieldName]: "",
        [ordersTableExcel.uploadDataFields[3]?.fieldName]: "",
        [ordersTableExcel.uploadDataFields[18]?.fieldName]: "",
        [ordersTableExcel.uploadDataFields[1]?.fieldName]: "",
        [ordersTableExcel.uploadDataFields[2]?.fieldName]: "",
        [ordersTableExcel.uploadDataFields[8]?.fieldName]: "",
        [ordersTableExcel.uploadDataFields[20]?.fieldName]: "",
        [ordersTableExcel.uploadDataFields[32]?.fieldName]: "",
        [ordersTableExcel.uploadDataFields[39]?.fieldName]: "",
        [ordersTableExcel.uploadDataFields[31]?.fieldName]: "",
        [ordersTableExcel.uploadDataFields[14]?.fieldName]: "",
        [ordersTableExcel.uploadDataFields[19]?.fieldName]: "",
        [ordersTableExcel.uploadDataFields[9]?.fieldName]: "",
        [ordersTableExcel.uploadDataFields[16]?.fieldName]: "",
        [ordersTableExcel.uploadDataFields[17]?.fieldName]: "",
        [ordersTableExcel.uploadDataFields[12]?.fieldName]: "",
        [ordersTableExcel.uploadDataFields[11]?.fieldName]: "",
        [ordersTableExcel.uploadDataFields[27]?.fieldName]: "",
        [ordersTableExcel.uploadDataFields[28]?.fieldName]: "",
        [ordersTableExcel.uploadDataFields[29]?.fieldName]: "",
        [ordersTableExcel.uploadDataFields[30]?.fieldName]: "",
        [ordersTableExcel.uploadDataFields[26]?.fieldName]: "",
        [ordersTableExcel.uploadDataFields[10]?.fieldName]: "",
        [ordersTableExcel.uploadDataFields[25]?.fieldName]: "",
        [ordersTableExcel.uploadDataFields[24]?.fieldName]: "",
        [ordersTableExcel.uploadDataFields[21]?.fieldName]: "",
        [ordersTableExcel.uploadDataFields[13]?.fieldName]: "",
        [ordersTableExcel.uploadDataFields[22]?.fieldName]: "",
        [ordersTableExcel.uploadDataFields[23]?.fieldName]: "",
      },
    ];
    const data1 = [
      {
        [ordersTableExcel.uploadDataFields[3]?.fieldName]:
          datasectors.data.sectors[0]?.name === undefined
            ? ""
            : datasectors.data.sectors[0]?.name,
        [ordersTableExcel.uploadDataFields[15]?.fieldName]:
          datacustomersSuppliers.data.customersSuppliers[0]?.name === undefined
            ? ""
            : datacustomersSuppliers.data.customersSuppliers[0]?.name,
        [ordersTableExcel.uploadDataFields[16]?.fieldName]:
          datacompanies.data.companies[0]?.name,
        [ordersTableExcel.uploadDataFields[17]?.fieldName]:
          datadistributionCenters.data.distributionCenters[0]?.name ===
          undefined
            ? ""
            : datadistributionCenters.data.distributionCenters[0]?.name,
        [ordersTableExcel.uploadDataFields[18]?.fieldName]:
          dataaddressBook.data.addressBook[0]?.name === undefined
            ? ""
            : dataaddressBook.data.addressBook[0]?.name,
        [ordersTableExcel.uploadDataFields[31]?.fieldName]: "Alta",
        [ordersTableExcel.uploadDataFields[39]?.fieldName]: "Envio",
        [ordersTableExcel.uploadDataFields[11]?.fieldName]: "Si",
        [ordersTableExcel.uploadDataFields[12]?.fieldName]: "Si",
        [ordersTableExcel.uploadDataFields[13]?.fieldName]: "Si",
        [ordersTableExcel.uploadDataFields[14]?.fieldName]: "Si",
      },
      {
        [ordersTableExcel.uploadDataFields[3]?.fieldName]:
          datasectors.data.sectors[1]?.name === undefined
            ? ""
            : datasectors.data.sectors[1]?.name,
        [ordersTableExcel.uploadDataFields[15]?.fieldName]:
          datacustomersSuppliers.data.customersSuppliers[1]?.name === undefined
            ? ""
            : datacustomersSuppliers.data.customersSuppliers[1]?.name,
        [ordersTableExcel.uploadDataFields[16]?.fieldName]: "",
        [ordersTableExcel.uploadDataFields[17]?.fieldName]:
          datadistributionCenters.data.distributionCenters[1]?.name ===
          undefined
            ? ""
            : datadistributionCenters.data.distributionCenters[1]?.name,
        [ordersTableExcel.uploadDataFields[18]?.fieldName]:
          dataaddressBook.data.addressBook[1]?.name === undefined
            ? ""
            : dataaddressBook.data.addressBook[1]?.name,
        [ordersTableExcel.uploadDataFields[31]?.fieldName]: "Media",
        [ordersTableExcel.uploadDataFields[39]?.fieldName]: "Recogida",
        [ordersTableExcel.uploadDataFields[11]?.fieldName]: "No",
        [ordersTableExcel.uploadDataFields[12]?.fieldName]: "No",
        [ordersTableExcel.uploadDataFields[13]?.fieldName]: "No",
        [ordersTableExcel.uploadDataFields[14]?.fieldName]: "No",
      },
      {
        [ordersTableExcel.uploadDataFields[3]?.fieldName]:
          datasectors.data.sectors[2]?.name === undefined
            ? ""
            : datasectors.data.sectors[2]?.name,
        [ordersTableExcel.uploadDataFields[15]?.fieldName]:
          datacustomersSuppliers.data.customersSuppliers[2]?.name === undefined
            ? ""
            : datacustomersSuppliers.data.customersSuppliers[2]?.name,
        [ordersTableExcel.uploadDataFields[16]?.fieldName]: "",
        [ordersTableExcel.uploadDataFields[17]?.fieldName]:
          datadistributionCenters.data.distributionCenters[2]?.name ===
          undefined
            ? ""
            : datadistributionCenters.data.distributionCenters[2]?.name,
        [ordersTableExcel.uploadDataFields[18]?.fieldName]:
          dataaddressBook.data.addressBook[2]?.name === undefined
            ? ""
            : dataaddressBook.data.addressBook[2]?.name,
        [ordersTableExcel.uploadDataFields[31]?.fieldName]: "Baja",
        [ordersTableExcel.uploadDataFields[39]?.fieldName]:
          "Servicio Personalizado",
      },
      {
        [ordersTableExcel.uploadDataFields[3]?.fieldName]:
          datasectors.data.sectors[3]?.name === undefined
            ? ""
            : datasectors.data.sectors[3]?.name,
        [ordersTableExcel.uploadDataFields[15]?.fieldName]:
          datacustomersSuppliers.data.customersSuppliers[2]?.name === undefined
            ? ""
            : datacustomersSuppliers.data.customersSuppliers[3]?.name,
        [ordersTableExcel.uploadDataFields[17]?.fieldName]:
          datadistributionCenters.data.distributionCenters[3]?.name ===
          undefined
            ? ""
            : datadistributionCenters.data.distributionCenters[3]?.name,
        [ordersTableExcel.uploadDataFields[18]?.fieldName]:
          dataaddressBook.data.addressBook[3]?.name === undefined
            ? ""
            : dataaddressBook.data.addressBook[3]?.name,
        [ordersTableExcel.uploadDataFields[39]?.fieldName]:
          "Traspaso de Almacenes",
      },
      {
        [ordersTableExcel.uploadDataFields[3]?.fieldName]:
          datasectors.data.sectors[4]?.name === undefined
            ? ""
            : datasectors.data.sectors[4]?.name,
        [ordersTableExcel.uploadDataFields[15]?.fieldName]:
          datacustomersSuppliers.data.customersSuppliers[2]?.name === undefined
            ? ""
            : datacustomersSuppliers.data.customersSuppliers[4]?.name,
        [ordersTableExcel.uploadDataFields[17]?.fieldName]:
          datadistributionCenters.data.distributionCenters[4]?.name ===
          undefined
            ? ""
            : datadistributionCenters.data.distributionCenters[4]?.name,
        [ordersTableExcel.uploadDataFields[18]?.fieldName]:
          dataaddressBook.data.addressBook[4]?.name === undefined
            ? ""
            : dataaddressBook.data.addressBook[4]?.name,
      },
      {
        [ordersTableExcel.uploadDataFields[3]?.fieldName]:
          datasectors.data.sectors[5]?.name === undefined
            ? ""
            : datasectors.data.sectors[5]?.name,
        [ordersTableExcel.uploadDataFields[15]?.fieldName]:
          datacustomersSuppliers.data.customersSuppliers[2]?.name === undefined
            ? ""
            : datacustomersSuppliers.data.customersSuppliers[5]?.name,
        [ordersTableExcel.uploadDataFields[17]?.fieldName]:
          datadistributionCenters.data.distributionCenters[5]?.name ===
          undefined
            ? ""
            : datadistributionCenters.data.distributionCenters[5]?.name,
        [ordersTableExcel.uploadDataFields[18]?.fieldName]:
          dataaddressBook.data.addressBook[5]?.name === undefined
            ? ""
            : dataaddressBook.data.addressBook[5]?.name,
      },
      {
        [ordersTableExcel.uploadDataFields[3]?.fieldName]:
          datasectors.data.sectors[6]?.name === undefined
            ? ""
            : datasectors.data.sectors[6]?.name,
        [ordersTableExcel.uploadDataFields[15]?.fieldName]:
          datacustomersSuppliers.data.customersSuppliers[2]?.name === undefined
            ? ""
            : datacustomersSuppliers.data.customersSuppliers[6]?.name,
        [ordersTableExcel.uploadDataFields[17]?.fieldName]:
          datadistributionCenters.data.distributionCenters[6]?.name ===
          undefined
            ? ""
            : datadistributionCenters.data.distributionCenters[6]?.name,
        [ordersTableExcel.uploadDataFields[18]?.fieldName]:
          dataaddressBook.data.addressBook[6]?.name === undefined
            ? ""
            : dataaddressBook.data.addressBook[6]?.name,
      },
      {
        [ordersTableExcel.uploadDataFields[3]?.fieldName]:
          datasectors.data.sectors[7]?.name === undefined
            ? ""
            : datasectors.data.sectors[7]?.name,
        [ordersTableExcel.uploadDataFields[15]?.fieldName]:
          datacustomersSuppliers.data.customersSuppliers[2]?.name === undefined
            ? ""
            : datacustomersSuppliers.data.customersSuppliers[7]?.name,
        [ordersTableExcel.uploadDataFields[17]?.fieldName]:
          datadistributionCenters.data.distributionCenters[7]?.name ===
          undefined
            ? ""
            : datadistributionCenters.data.distributionCenters[7]?.name,
        [ordersTableExcel.uploadDataFields[18]?.fieldName]:
          dataaddressBook.data.addressBook[7]?.name === undefined
            ? ""
            : dataaddressBook.data.addressBook[7]?.name,
      },
      {
        [ordersTableExcel.uploadDataFields[3]?.fieldName]:
          datasectors.data.sectors[8]?.name === undefined
            ? ""
            : datasectors.data.sectors[8]?.name,
        [ordersTableExcel.uploadDataFields[15]?.fieldName]:
          datacustomersSuppliers.data.customersSuppliers[2]?.name === undefined
            ? ""
            : datacustomersSuppliers.data.customersSuppliers[8]?.name,
        [ordersTableExcel.uploadDataFields[17]?.fieldName]:
          datadistributionCenters.data.distributionCenters[8]?.name ===
          undefined
            ? ""
            : datadistributionCenters.data.distributionCenters[8]?.name,
        [ordersTableExcel.uploadDataFields[18]?.fieldName]:
          dataaddressBook.data.addressBook[8]?.name === undefined
            ? ""
            : dataaddressBook.data.addressBook[8]?.name,
      },
      {
        [ordersTableExcel.uploadDataFields[3]?.fieldName]:
          datasectors.data.sectors[9]?.name === undefined
            ? ""
            : datasectors.data.sectors[9]?.name,
        [ordersTableExcel.uploadDataFields[15]?.fieldName]:
          datacustomersSuppliers.data.customersSuppliers[2]?.name === undefined
            ? ""
            : datacustomersSuppliers.data.customersSuppliers[9]?.name,
        [ordersTableExcel.uploadDataFields[17]?.fieldName]:
          datadistributionCenters.data.distributionCenters[9]?.name ===
          undefined
            ? ""
            : datadistributionCenters.data.distributionCenters[9]?.name,
        [ordersTableExcel.uploadDataFields[18]?.fieldName]:
          dataaddressBook.data.addressBook[9]?.name === undefined
            ? ""
            : dataaddressBook.data.addressBook[9]?.name,
      },
      {
        [ordersTableExcel.uploadDataFields[3]?.fieldName]:
          datasectors.data.sectors[10]?.name === undefined
            ? ""
            : datasectors.data.sectors[10]?.name,
        [ordersTableExcel.uploadDataFields[15]?.fieldName]:
          datacustomersSuppliers.data.customersSuppliers[2]?.name === undefined
            ? ""
            : datacustomersSuppliers.data.customersSuppliers[10]?.name,
        [ordersTableExcel.uploadDataFields[17]?.fieldName]:
          datadistributionCenters.data.distributionCenters[10]?.name ===
          undefined
            ? ""
            : datadistributionCenters.data.distributionCenters[10]?.name,
        [ordersTableExcel.uploadDataFields[18]?.fieldName]:
          dataaddressBook.data.addressBook[10]?.name === undefined
            ? ""
            : dataaddressBook.data.addressBook[10]?.name,
      },
      {
        [ordersTableExcel.uploadDataFields[3]?.fieldName]:
          datasectors.data.sectors[11]?.name === undefined
            ? ""
            : datasectors.data.sectors[11]?.name,
        [ordersTableExcel.uploadDataFields[15]?.fieldName]:
          datacustomersSuppliers.data.customersSuppliers[2]?.name === undefined
            ? ""
            : datacustomersSuppliers.data.customersSuppliers[11]?.name,
        [ordersTableExcel.uploadDataFields[17]?.fieldName]:
          datadistributionCenters.data.distributionCenters[11]?.name ===
          undefined
            ? ""
            : datadistributionCenters.data.distributionCenters[11]?.name,
        [ordersTableExcel.uploadDataFields[18]?.fieldName]:
          dataaddressBook.data.addressBook[11]?.name === undefined
            ? ""
            : dataaddressBook.data.addressBook[11]?.name,
      },
      {
        [ordersTableExcel.uploadDataFields[3]?.fieldName]:
          datasectors.data.sectors[12]?.name === undefined
            ? ""
            : datasectors.data.sectors[12]?.name,
        [ordersTableExcel.uploadDataFields[15]?.fieldName]:
          datacustomersSuppliers.data.customersSuppliers[2]?.name === undefined
            ? ""
            : datacustomersSuppliers.data.customersSuppliers[12]?.name,
        [ordersTableExcel.uploadDataFields[17]?.fieldName]:
          datadistributionCenters.data.distributionCenters[12]?.name ===
          undefined
            ? ""
            : datadistributionCenters.data.distributionCenters[12]?.name,
        [ordersTableExcel.uploadDataFields[18]?.fieldName]:
          dataaddressBook.data.addressBook[12]?.name === undefined
            ? ""
            : dataaddressBook.data.addressBook[12]?.name,
      },
      {
        [ordersTableExcel.uploadDataFields[3]?.fieldName]:
          datasectors.data.sectors[13]?.name === undefined
            ? ""
            : datasectors.data.sectors[13]?.name,
        [ordersTableExcel.uploadDataFields[15]?.fieldName]:
          datacustomersSuppliers.data.customersSuppliers[2]?.name === undefined
            ? ""
            : datacustomersSuppliers.data.customersSuppliers[13]?.name,
        [ordersTableExcel.uploadDataFields[17]?.fieldName]:
          datadistributionCenters.data.distributionCenters[13]?.name ===
          undefined
            ? ""
            : datadistributionCenters.data.distributionCenters[13]?.name,
        [ordersTableExcel.uploadDataFields[18]?.fieldName]:
          dataaddressBook.data.addressBook[13]?.name === undefined
            ? ""
            : dataaddressBook.data.addressBook[13]?.name,
      },
      {
        [ordersTableExcel.uploadDataFields[3]?.fieldName]:
          datasectors.data.sectors[14]?.name === undefined
            ? ""
            : datasectors.data.sectors[14]?.name,
        [ordersTableExcel.uploadDataFields[15]?.fieldName]:
          datacustomersSuppliers.data.customersSuppliers[2]?.name === undefined
            ? ""
            : datacustomersSuppliers.data.customersSuppliers[14]?.name,
        [ordersTableExcel.uploadDataFields[17]?.fieldName]:
          datadistributionCenters.data.distributionCenters[14]?.name ===
          undefined
            ? ""
            : datadistributionCenters.data.distributionCenters[14]?.name,
        [ordersTableExcel.uploadDataFields[18]?.fieldName]:
          dataaddressBook.data.addressBook[14]?.name === undefined
            ? ""
            : dataaddressBook.data.addressBook[14]?.name,
      },
      {
        [ordersTableExcel.uploadDataFields[3]?.fieldName]:
          datasectors.data.sectors[15]?.name === undefined
            ? ""
            : datasectors.data.sectors[15]?.name,
        [ordersTableExcel.uploadDataFields[15]?.fieldName]:
          datacustomersSuppliers.data.customersSuppliers[2]?.name === undefined
            ? ""
            : datacustomersSuppliers.data.customersSuppliers[15]?.name,
        [ordersTableExcel.uploadDataFields[17]?.fieldName]:
          datadistributionCenters.data.distributionCenters[15]?.name ===
          undefined
            ? ""
            : datadistributionCenters.data.distributionCenters[15]?.name,
        [ordersTableExcel.uploadDataFields[18]?.fieldName]:
          dataaddressBook.data.addressBook[15]?.name === undefined
            ? ""
            : dataaddressBook.data.addressBook[15]?.name,
      },
      {
        [ordersTableExcel.uploadDataFields[3]?.fieldName]:
          datasectors.data.sectors[16]?.name === undefined
            ? ""
            : datasectors.data.sectors[16]?.name,
        [ordersTableExcel.uploadDataFields[15]?.fieldName]:
          datacustomersSuppliers.data.customersSuppliers[2]?.name === undefined
            ? ""
            : datacustomersSuppliers.data.customersSuppliers[16]?.name,
        [ordersTableExcel.uploadDataFields[17]?.fieldName]:
          datadistributionCenters.data.distributionCenters[16]?.name ===
          undefined
            ? ""
            : datadistributionCenters.data.distributionCenters[16]?.name,
        [ordersTableExcel.uploadDataFields[18]?.fieldName]:
          dataaddressBook.data.addressBook[16]?.name === undefined
            ? ""
            : dataaddressBook.data.addressBook[16]?.name,
      },
      {
        [ordersTableExcel.uploadDataFields[3]?.fieldName]:
          datasectors.data.sectors[17]?.name === undefined
            ? ""
            : datasectors.data.sectors[17]?.name,
        [ordersTableExcel.uploadDataFields[15]?.fieldName]:
          datacustomersSuppliers.data.customersSuppliers[2]?.name === undefined
            ? ""
            : datacustomersSuppliers.data.customersSuppliers[17]?.name,
        [ordersTableExcel.uploadDataFields[17]?.fieldName]:
          datadistributionCenters.data.distributionCenters[17]?.name ===
          undefined
            ? ""
            : datadistributionCenters.data.distributionCenters[17]?.name,
        [ordersTableExcel.uploadDataFields[18]?.fieldName]:
          dataaddressBook.data.addressBook[17]?.name === undefined
            ? ""
            : dataaddressBook.data.addressBook[17]?.name,
      },
      {
        [ordersTableExcel.uploadDataFields[3]?.fieldName]:
          datasectors.data.sectors[18]?.name === undefined
            ? ""
            : datasectors.data.sectors[18]?.name,
        [ordersTableExcel.uploadDataFields[15]?.fieldName]:
          datacustomersSuppliers.data.customersSuppliers[2]?.name === undefined
            ? ""
            : datacustomersSuppliers.data.customersSuppliers[18]?.name,
        [ordersTableExcel.uploadDataFields[17]?.fieldName]:
          datadistributionCenters.data.distributionCenters[18]?.name ===
          undefined
            ? ""
            : datadistributionCenters.data.distributionCenters[18]?.name,
        [ordersTableExcel.uploadDataFields[18]?.fieldName]:
          dataaddressBook.data.addressBook[18]?.name === undefined
            ? ""
            : dataaddressBook.data.addressBook[18]?.name,
      },
      {
        [ordersTableExcel.uploadDataFields[3]?.fieldName]:
          datasectors.data.sectors[19]?.name === undefined
            ? ""
            : datasectors.data.sectors[19]?.name,
        [ordersTableExcel.uploadDataFields[15]?.fieldName]:
          datacustomersSuppliers.data.customersSuppliers[2]?.name === undefined
            ? ""
            : datacustomersSuppliers.data.customersSuppliers[19]?.name,
        [ordersTableExcel.uploadDataFields[17]?.fieldName]:
          datadistributionCenters.data.distributionCenters[19]?.name ===
          undefined
            ? ""
            : datadistributionCenters.data.distributionCenters[19]?.name,
        [ordersTableExcel.uploadDataFields[18]?.fieldName]:
          dataaddressBook.data.addressBook[19]?.name === undefined
            ? ""
            : dataaddressBook.data.addressBook[19]?.name,
      },
      {
        [ordersTableExcel.uploadDataFields[3]?.fieldName]:
          datasectors.data.sectors[20]?.name === undefined
            ? ""
            : datasectors.data.sectors[20]?.name,
        [ordersTableExcel.uploadDataFields[15]?.fieldName]:
          datacustomersSuppliers.data.customersSuppliers[2]?.name === undefined
            ? ""
            : datacustomersSuppliers.data.customersSuppliers[20]?.name,
        [ordersTableExcel.uploadDataFields[17]?.fieldName]:
          datadistributionCenters.data.distributionCenters[20]?.name ===
          undefined
            ? ""
            : datadistributionCenters.data.distributionCenters[20]?.name,
        [ordersTableExcel.uploadDataFields[18]?.fieldName]:
          dataaddressBook.data.addressBook[20]?.name === undefined
            ? ""
            : dataaddressBook.data.addressBook[20]?.name,
      },
      {
        [ordersTableExcel.uploadDataFields[3]?.fieldName]:
          datasectors.data.sectors[21]?.name === undefined
            ? ""
            : datasectors.data.sectors[21]?.name,
        [ordersTableExcel.uploadDataFields[15]?.fieldName]:
          datacustomersSuppliers.data.customersSuppliers[2]?.name === undefined
            ? ""
            : datacustomersSuppliers.data.customersSuppliers[21]?.name,
        [ordersTableExcel.uploadDataFields[17]?.fieldName]:
          datadistributionCenters.data.distributionCenters[21]?.name ===
          undefined
            ? ""
            : datadistributionCenters.data.distributionCenters[21]?.name,
        [ordersTableExcel.uploadDataFields[18]?.fieldName]:
          dataaddressBook.data.addressBook[21]?.name === undefined
            ? ""
            : dataaddressBook.data.addressBook[21]?.name,
      },
      {
        [ordersTableExcel.uploadDataFields[3]?.fieldName]:
          datasectors.data.sectors[22]?.name === undefined
            ? ""
            : datasectors.data.sectors[22]?.name,
        [ordersTableExcel.uploadDataFields[15]?.fieldName]:
          datacustomersSuppliers.data.customersSuppliers[2]?.name === undefined
            ? ""
            : datacustomersSuppliers.data.customersSuppliers[22]?.name,
        [ordersTableExcel.uploadDataFields[17]?.fieldName]:
          datadistributionCenters.data.distributionCenters[22]?.name ===
          undefined
            ? ""
            : datadistributionCenters.data.distributionCenters[22]?.name,
        [ordersTableExcel.uploadDataFields[18]?.fieldName]:
          dataaddressBook.data.addressBook[22]?.name === undefined
            ? ""
            : dataaddressBook.data.addressBook[22]?.name,
      },
      {
        [ordersTableExcel.uploadDataFields[3]?.fieldName]:
          datasectors.data.sectors[23]?.name === undefined
            ? ""
            : datasectors.data.sectors[23]?.name,
        [ordersTableExcel.uploadDataFields[15]?.fieldName]:
          datacustomersSuppliers.data.customersSuppliers[2]?.name === undefined
            ? ""
            : datacustomersSuppliers.data.customersSuppliers[23]?.name,
        [ordersTableExcel.uploadDataFields[17]?.fieldName]:
          datadistributionCenters.data.distributionCenters[23]?.name ===
          undefined
            ? ""
            : datadistributionCenters.data.distributionCenters[23]?.name,
        [ordersTableExcel.uploadDataFields[18]?.fieldName]:
          dataaddressBook.data.addressBook[23]?.name === undefined
            ? ""
            : dataaddressBook.data.addressBook[23]?.name,
      },
      {
        [ordersTableExcel.uploadDataFields[3]?.fieldName]:
          datasectors.data.sectors[24]?.name === undefined
            ? ""
            : datasectors.data.sectors[24]?.name,
        [ordersTableExcel.uploadDataFields[15]?.fieldName]:
          datacustomersSuppliers.data.customersSuppliers[2]?.name === undefined
            ? ""
            : datacustomersSuppliers.data.customersSuppliers[24]?.name,
        [ordersTableExcel.uploadDataFields[17]?.fieldName]:
          datadistributionCenters.data.distributionCenters[24]?.name ===
          undefined
            ? ""
            : datadistributionCenters.data.distributionCenters[24]?.name,
        [ordersTableExcel.uploadDataFields[18]?.fieldName]:
          dataaddressBook.data.addressBook[24]?.name === undefined
            ? ""
            : dataaddressBook.data.addressBook[24]?.name,
      },
      {
        [ordersTableExcel.uploadDataFields[3]?.fieldName]:
          datasectors.data.sectors[25]?.name === undefined
            ? ""
            : datasectors.data.sectors[25]?.name,
        [ordersTableExcel.uploadDataFields[15]?.fieldName]:
          datacustomersSuppliers.data.customersSuppliers[2]?.name === undefined
            ? ""
            : datacustomersSuppliers.data.customersSuppliers[25]?.name,
        [ordersTableExcel.uploadDataFields[17]?.fieldName]:
          datadistributionCenters.data.distributionCenters[25]?.name ===
          undefined
            ? ""
            : datadistributionCenters.data.distributionCenters[25]?.name,
        [ordersTableExcel.uploadDataFields[18]?.fieldName]:
          dataaddressBook.data.addressBook[25]?.name === undefined
            ? ""
            : dataaddressBook.data.addressBook[25]?.name,
      },
      {
        [ordersTableExcel.uploadDataFields[3]?.fieldName]:
          datasectors.data.sectors[26]?.name === undefined
            ? ""
            : datasectors.data.sectors[26]?.name,
        [ordersTableExcel.uploadDataFields[15]?.fieldName]:
          datacustomersSuppliers.data.customersSuppliers[2]?.name === undefined
            ? ""
            : datacustomersSuppliers.data.customersSuppliers[26]?.name,
        [ordersTableExcel.uploadDataFields[17]?.fieldName]:
          datadistributionCenters.data.distributionCenters[26]?.name ===
          undefined
            ? ""
            : datadistributionCenters.data.distributionCenters[26]?.name,
        [ordersTableExcel.uploadDataFields[18]?.fieldName]:
          dataaddressBook.data.addressBook[26]?.name === undefined
            ? ""
            : dataaddressBook.data.addressBook[26]?.name,
      },
      {
        [ordersTableExcel.uploadDataFields[3]?.fieldName]:
          datasectors.data.sectors[27]?.name === undefined
            ? ""
            : datasectors.data.sectors[27]?.name,
        [ordersTableExcel.uploadDataFields[15]?.fieldName]:
          datacustomersSuppliers.data.customersSuppliers[2]?.name === undefined
            ? ""
            : datacustomersSuppliers.data.customersSuppliers[27]?.name,
        [ordersTableExcel.uploadDataFields[17]?.fieldName]:
          datadistributionCenters.data.distributionCenters[27]?.name ===
          undefined
            ? ""
            : datadistributionCenters.data.distributionCenters[27]?.name,
        [ordersTableExcel.uploadDataFields[18]?.fieldName]:
          dataaddressBook.data.addressBook[27]?.name === undefined
            ? ""
            : dataaddressBook.data.addressBook[27]?.name,
      },
      {
        [ordersTableExcel.uploadDataFields[3]?.fieldName]:
          datasectors.data.sectors[28]?.name === undefined
            ? ""
            : datasectors.data.sectors[28]?.name,
        [ordersTableExcel.uploadDataFields[15]?.fieldName]:
          datacustomersSuppliers.data.customersSuppliers[2]?.name === undefined
            ? ""
            : datacustomersSuppliers.data.customersSuppliers[28]?.name,
        [ordersTableExcel.uploadDataFields[17]?.fieldName]:
          datadistributionCenters.data.distributionCenters[28]?.name ===
          undefined
            ? ""
            : datadistributionCenters.data.distributionCenters[28]?.name,
        [ordersTableExcel.uploadDataFields[18]?.fieldName]:
          dataaddressBook.data.addressBook[28]?.name === undefined
            ? ""
            : dataaddressBook.data.addressBook[28]?.name,
      },
      {
        [ordersTableExcel.uploadDataFields[3]?.fieldName]:
          datasectors.data.sectors[29]?.name === undefined
            ? ""
            : datasectors.data.sectors[29]?.name,
        [ordersTableExcel.uploadDataFields[15]?.fieldName]:
          datacustomersSuppliers.data.customersSuppliers[2]?.name === undefined
            ? ""
            : datacustomersSuppliers.data.customersSuppliers[29]?.name,
        [ordersTableExcel.uploadDataFields[17]?.fieldName]:
          datadistributionCenters.data.distributionCenters[29]?.name ===
          undefined
            ? ""
            : datadistributionCenters.data.distributionCenters[29]?.name,
        [ordersTableExcel.uploadDataFields[18]?.fieldName]:
          dataaddressBook.data.addressBook[29]?.name === undefined
            ? ""
            : dataaddressBook.data.addressBook[29]?.name,
      },
      {
        [ordersTableExcel.uploadDataFields[3]?.fieldName]:
          datasectors.data.sectors[30]?.name === undefined
            ? ""
            : datasectors.data.sectors[30]?.name,
        [ordersTableExcel.uploadDataFields[15]?.fieldName]:
          datacustomersSuppliers.data.customersSuppliers[2]?.name === undefined
            ? ""
            : datacustomersSuppliers.data.customersSuppliers[30]?.name,
        [ordersTableExcel.uploadDataFields[17]?.fieldName]:
          datadistributionCenters.data.distributionCenters[30]?.name ===
          undefined
            ? ""
            : datadistributionCenters.data.distributionCenters[30]?.name,
        [ordersTableExcel.uploadDataFields[18]?.fieldName]:
          dataaddressBook.data.addressBook[30]?.name === undefined
            ? ""
            : dataaddressBook.data.addressBook[30]?.name,
      },
      {
        [ordersTableExcel.uploadDataFields[3]?.fieldName]:
          datasectors.data.sectors[31]?.name === undefined
            ? ""
            : datasectors.data.sectors[31]?.name,
        [ordersTableExcel.uploadDataFields[15]?.fieldName]:
          datacustomersSuppliers.data.customersSuppliers[2]?.name === undefined
            ? ""
            : datacustomersSuppliers.data.customersSuppliers[31]?.name,
        [ordersTableExcel.uploadDataFields[17]?.fieldName]:
          datadistributionCenters.data.distributionCenters[31]?.name ===
          undefined
            ? ""
            : datadistributionCenters.data.distributionCenters[31]?.name,
        [ordersTableExcel.uploadDataFields[18]?.fieldName]:
          dataaddressBook.data.addressBook[31]?.name === undefined
            ? ""
            : dataaddressBook.data.addressBook[31]?.name,
      },
      {
        [ordersTableExcel.uploadDataFields[3]?.fieldName]:
          datasectors.data.sectors[32]?.name === undefined
            ? ""
            : datasectors.data.sectors[32]?.name,
        [ordersTableExcel.uploadDataFields[15]?.fieldName]:
          datacustomersSuppliers.data.customersSuppliers[2]?.name === undefined
            ? ""
            : datacustomersSuppliers.data.customersSuppliers[32]?.name,
        [ordersTableExcel.uploadDataFields[17]?.fieldName]:
          datadistributionCenters.data.distributionCenters[32]?.name ===
          undefined
            ? ""
            : datadistributionCenters.data.distributionCenters[32]?.name,
        [ordersTableExcel.uploadDataFields[18]?.fieldName]:
          dataaddressBook.data.addressBook[32]?.name === undefined
            ? ""
            : dataaddressBook.data.addressBook[32]?.name,
      },
      {
        [ordersTableExcel.uploadDataFields[3]?.fieldName]:
          datasectors.data.sectors[33]?.name === undefined
            ? ""
            : datasectors.data.sectors[33]?.name,
        [ordersTableExcel.uploadDataFields[15]?.fieldName]:
          datacustomersSuppliers.data.customersSuppliers[2]?.name === undefined
            ? ""
            : datacustomersSuppliers.data.customersSuppliers[33]?.name,
        [ordersTableExcel.uploadDataFields[17]?.fieldName]:
          datadistributionCenters.data.distributionCenters[33]?.name ===
          undefined
            ? ""
            : datadistributionCenters.data.distributionCenters[33]?.name,
        [ordersTableExcel.uploadDataFields[18]?.fieldName]:
          dataaddressBook.data.addressBook[33]?.name === undefined
            ? ""
            : dataaddressBook.data.addressBook[33]?.name,
      },
      {
        [ordersTableExcel.uploadDataFields[3]?.fieldName]:
          datasectors.data.sectors[34]?.name === undefined
            ? ""
            : datasectors.data.sectors[34]?.name,
        [ordersTableExcel.uploadDataFields[15]?.fieldName]:
          datacustomersSuppliers.data.customersSuppliers[2]?.name === undefined
            ? ""
            : datacustomersSuppliers.data.customersSuppliers[34]?.name,
        [ordersTableExcel.uploadDataFields[17]?.fieldName]:
          datadistributionCenters.data.distributionCenters[34]?.name ===
          undefined
            ? ""
            : datadistributionCenters.data.distributionCenters[34]?.name,
        [ordersTableExcel.uploadDataFields[18]?.fieldName]:
          dataaddressBook.data.addressBook[34]?.name === undefined
            ? ""
            : dataaddressBook.data.addressBook[34]?.name,
      },
      {
        [ordersTableExcel.uploadDataFields[3]?.fieldName]:
          datasectors.data.sectors[35]?.name === undefined
            ? ""
            : datasectors.data.sectors[35]?.name,
        [ordersTableExcel.uploadDataFields[15]?.fieldName]:
          datacustomersSuppliers.data.customersSuppliers[2]?.name === undefined
            ? ""
            : datacustomersSuppliers.data.customersSuppliers[35]?.name,
        [ordersTableExcel.uploadDataFields[17]?.fieldName]:
          datadistributionCenters.data.distributionCenters[35]?.name ===
          undefined
            ? ""
            : datadistributionCenters.data.distributionCenters[35]?.name,
        [ordersTableExcel.uploadDataFields[18]?.fieldName]:
          dataaddressBook.data.addressBook[35]?.name === undefined
            ? ""
            : dataaddressBook.data.addressBook[35]?.name,
      },
      {
        [ordersTableExcel.uploadDataFields[3]?.fieldName]:
          datasectors.data.sectors[36]?.name === undefined
            ? ""
            : datasectors.data.sectors[36]?.name,
        [ordersTableExcel.uploadDataFields[15]?.fieldName]:
          datacustomersSuppliers.data.customersSuppliers[2]?.name === undefined
            ? ""
            : datacustomersSuppliers.data.customersSuppliers[36]?.name,
        [ordersTableExcel.uploadDataFields[17]?.fieldName]:
          datadistributionCenters.data.distributionCenters[36]?.name ===
          undefined
            ? ""
            : datadistributionCenters.data.distributionCenters[36]?.name,
        [ordersTableExcel.uploadDataFields[18]?.fieldName]:
          dataaddressBook.data.addressBook[36]?.name === undefined
            ? ""
            : dataaddressBook.data.addressBook[36]?.name,
      },
      {
        [ordersTableExcel.uploadDataFields[3]?.fieldName]:
          datasectors.data.sectors[37]?.name === undefined
            ? ""
            : datasectors.data.sectors[37]?.name,
        [ordersTableExcel.uploadDataFields[15]?.fieldName]:
          datacustomersSuppliers.data.customersSuppliers[2]?.name === undefined
            ? ""
            : datacustomersSuppliers.data.customersSuppliers[37]?.name,
        [ordersTableExcel.uploadDataFields[17]?.fieldName]:
          datadistributionCenters.data.distributionCenters[37]?.name ===
          undefined
            ? ""
            : datadistributionCenters.data.distributionCenters[37]?.name,
        [ordersTableExcel.uploadDataFields[18]?.fieldName]:
          dataaddressBook.data.addressBook[37]?.name === undefined
            ? ""
            : dataaddressBook.data.addressBook[37]?.name,
      },
      {
        [ordersTableExcel.uploadDataFields[3]?.fieldName]:
          datasectors.data.sectors[38]?.name === undefined
            ? ""
            : datasectors.data.sectors[38]?.name,
        [ordersTableExcel.uploadDataFields[15]?.fieldName]:
          datacustomersSuppliers.data.customersSuppliers[2]?.name === undefined
            ? ""
            : datacustomersSuppliers.data.customersSuppliers[38]?.name,
        [ordersTableExcel.uploadDataFields[17]?.fieldName]:
          datadistributionCenters.data.distributionCenters[38]?.name ===
          undefined
            ? ""
            : datadistributionCenters.data.distributionCenters[38]?.name,
        [ordersTableExcel.uploadDataFields[18]?.fieldName]:
          dataaddressBook.data.addressBook[38]?.name === undefined
            ? ""
            : dataaddressBook.data.addressBook[38]?.name,
      },
      {
        [ordersTableExcel.uploadDataFields[3]?.fieldName]:
          datasectors.data.sectors[39]?.name === undefined
            ? ""
            : datasectors.data.sectors[39]?.name,
        [ordersTableExcel.uploadDataFields[15]?.fieldName]:
          datacustomersSuppliers.data.customersSuppliers[2]?.name === undefined
            ? ""
            : datacustomersSuppliers.data.customersSuppliers[39]?.name,
        [ordersTableExcel.uploadDataFields[17]?.fieldName]:
          datadistributionCenters.data.distributionCenters[39]?.name ===
          undefined
            ? ""
            : datadistributionCenters.data.distributionCenters[39]?.name,
        [ordersTableExcel.uploadDataFields[18]?.fieldName]:
          dataaddressBook.data.addressBook[39]?.name === undefined
            ? ""
            : dataaddressBook.data.addressBook[39]?.name,
      },
      {
        [ordersTableExcel.uploadDataFields[3]?.fieldName]:
          datasectors.data.sectors[40]?.name === undefined
            ? ""
            : datasectors.data.sectors[40]?.name,
        [ordersTableExcel.uploadDataFields[15]?.fieldName]:
          datacustomersSuppliers.data.customersSuppliers[2]?.name === undefined
            ? ""
            : datacustomersSuppliers.data.customersSuppliers[40]?.name,
        [ordersTableExcel.uploadDataFields[17]?.fieldName]:
          datadistributionCenters.data.distributionCenters[40]?.name ===
          undefined
            ? ""
            : datadistributionCenters.data.distributionCenters[40]?.name,
        [ordersTableExcel.uploadDataFields[18]?.fieldName]:
          dataaddressBook.data.addressBook[40]?.name === undefined
            ? ""
            : dataaddressBook.data.addressBook[40]?.name,
      },
      {
        [ordersTableExcel.uploadDataFields[3]?.fieldName]:
          datasectors.data.sectors[41]?.name === undefined
            ? ""
            : datasectors.data.sectors[41]?.name,
        [ordersTableExcel.uploadDataFields[15]?.fieldName]:
          datacustomersSuppliers.data.customersSuppliers[2]?.name === undefined
            ? ""
            : datacustomersSuppliers.data.customersSuppliers[41]?.name,
        [ordersTableExcel.uploadDataFields[17]?.fieldName]:
          datadistributionCenters.data.distributionCenters[41]?.name ===
          undefined
            ? ""
            : datadistributionCenters.data.distributionCenters[41]?.name,
        [ordersTableExcel.uploadDataFields[18]?.fieldName]:
          dataaddressBook.data.addressBook[41]?.name === undefined
            ? ""
            : dataaddressBook.data.addressBook[41]?.name,
      },
      {
        [ordersTableExcel.uploadDataFields[3]?.fieldName]:
          datasectors.data.sectors[42]?.name === undefined
            ? ""
            : datasectors.data.sectors[42]?.name,
        [ordersTableExcel.uploadDataFields[15]?.fieldName]:
          datacustomersSuppliers.data.customersSuppliers[2]?.name === undefined
            ? ""
            : datacustomersSuppliers.data.customersSuppliers[42]?.name,
        [ordersTableExcel.uploadDataFields[17]?.fieldName]:
          datadistributionCenters.data.distributionCenters[42]?.name ===
          undefined
            ? ""
            : datadistributionCenters.data.distributionCenters[42]?.name,
        [ordersTableExcel.uploadDataFields[18]?.fieldName]:
          dataaddressBook.data.addressBook[42]?.name === undefined
            ? ""
            : dataaddressBook.data.addressBook[42]?.name,
      },
    ];
    const validationData = ['Gonzalo', 'Catalina'];
    const validation = {
    ref: 'N2:N10',
    dataValidation: {
      type: 'list',
      formulae: [`"${validationData.join(',')}"`]
    }
  };
    // Crear un objeto WorkBook y agregar una hoja de trabajo
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(data);
    worksheet['!validations'] = [validation];
    const worksheet1 = XLSX.utils.json_to_sheet(data1);
    // Agregar la hoja de trabajo al WorkBook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Formato de Cabecera");
    XLSX.utils.book_append_sheet(workbook, worksheet1, "Maestros");
    // Generar el archivo Excel y descargarlo
    const wbout = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    const blob = new Blob([wbout], { type: "application/octet-stream" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "Formato de Cabecera.xlsx";
    link.click();
    URL.revokeObjectURL(url);
  };
  const generateExcelLine = () => {
    const data = [
      {
        [ordersTableExcelLine.uploadDataFields[0]?.fieldName]: "",
        [ordersTableExcelLine.uploadDataFields[1]?.fieldName]: "",
        [ordersTableExcelLine.uploadDataFields[2]?.fieldName]: "",
        [ordersTableExcelLine.uploadDataFields[3]?.fieldName]: "",
        [ordersTableExcelLine.uploadDataFields[4]?.fieldName]: "",
        [ordersTableExcelLine.uploadDataFields[5]?.fieldName]: "",
      },
    ];
    const data1 = [
      {
        [ordersTableExcelLine.uploadDataFields[4]?.fieldName]:
          datapackages.data.packages[0]?.name === undefined
            ? ""
            : datapackages.data.packages[0].name,
      },
      {
        [ordersTableExcelLine.uploadDataFields[4]?.fieldName]:
          datapackages.data.packages[1]?.name === undefined
            ? ""
            : datapackages.data.packages[1].name,
      },
      {
        [ordersTableExcelLine.uploadDataFields[4]?.fieldName]:
          datapackages.data.packages[2]?.name === undefined
            ? ""
            : datapackages.data.packages[2].name,
      },
      {
        [ordersTableExcelLine.uploadDataFields[4]?.fieldName]:
          datapackages.data.packages[3]?.name === undefined
            ? ""
            : datapackages.data.packages[3].name,
      },
      {
        [ordersTableExcelLine.uploadDataFields[4]?.fieldName]:
          datapackages.data.packages[4]?.name === undefined
            ? ""
            : datapackages.data.packages[4].name,
      },
      {
        [ordersTableExcelLine.uploadDataFields[4]?.fieldName]:
          datapackages.data.packages[5]?.name === undefined
            ? ""
            : datapackages.data.packages[5].name,
      },
      {
        [ordersTableExcelLine.uploadDataFields[4]?.fieldName]:
          datapackages.data.packages[6]?.name === undefined
            ? ""
            : datapackages.data.packages[6].name,
      },
      {
        [ordersTableExcelLine.uploadDataFields[4]?.fieldName]:
          datapackages.data.packages[7]?.name === undefined
            ? ""
            : datapackages.data.packages[7].name,
      },
      {
        [ordersTableExcelLine.uploadDataFields[4]?.fieldName]:
          datapackages.data.packages[8]?.name === undefined
            ? ""
            : datapackages.data.packages[8].name,
      },
      {
        [ordersTableExcelLine.uploadDataFields[4]?.fieldName]:
          datapackages.data.packages[9]?.name === undefined
            ? ""
            : datapackages.data.packages[9].name,
      },
      {
        [ordersTableExcelLine.uploadDataFields[4]?.fieldName]:
          datapackages.data.packages[10]?.name === undefined
            ? ""
            : datapackages.data.packages[10].name,
      },
      {
        [ordersTableExcelLine.uploadDataFields[4]?.fieldName]:
          datapackages.data.packages[11]?.name === undefined
            ? ""
            : datapackages.data.packages[11].name,
      },
      {
        [ordersTableExcelLine.uploadDataFields[4]?.fieldName]:
          datapackages.data.packages[12]?.name === undefined
            ? ""
            : datapackages.data.packages[12].name,
      },
      {
        [ordersTableExcelLine.uploadDataFields[4]?.fieldName]:
          datapackages.data.packages[13]?.name === undefined
            ? ""
            : datapackages.data.packages[13].name,
      },
      {
        [ordersTableExcelLine.uploadDataFields[4]?.fieldName]:
          datapackages.data.packages[14]?.name === undefined
            ? ""
            : datapackages.data.packages[14].name,
      },
      {
        [ordersTableExcelLine.uploadDataFields[4]?.fieldName]:
          datapackages.data.packages[15]?.name === undefined
            ? ""
            : datapackages.data.packages[15].name,
      },
      {
        [ordersTableExcelLine.uploadDataFields[4]?.fieldName]:
          datapackages.data.packages[16]?.name === undefined
            ? ""
            : datapackages.data.packages[16].name,
      },
      {
        [ordersTableExcelLine.uploadDataFields[4]?.fieldName]:
          datapackages.data.packages[17]?.name === undefined
            ? ""
            : datapackages.data.packages[17].name,
      },
      {
        [ordersTableExcelLine.uploadDataFields[4]?.fieldName]:
          datapackages.data.packages[18]?.name === undefined
            ? ""
            : datapackages.data.packages[18].name,
      },
      {
        [ordersTableExcelLine.uploadDataFields[4]?.fieldName]:
          datapackages.data.packages[19]?.name === undefined
            ? ""
            : datapackages.data.packages[19].name,
      },
      {
        [ordersTableExcelLine.uploadDataFields[4]?.fieldName]:
          datapackages.data.packages[20]?.name === undefined
            ? ""
            : datapackages.data.packages[20].name,
      },
      {
        [ordersTableExcelLine.uploadDataFields[4]?.fieldName]:
          datapackages.data.packages[21]?.name === undefined
            ? ""
            : datapackages.data.packages[21].name,
      },
      {
        [ordersTableExcelLine.uploadDataFields[4]?.fieldName]:
          datapackages.data.packages[22]?.name === undefined
            ? ""
            : datapackages.data.packages[22].name,
      },
      {
        [ordersTableExcelLine.uploadDataFields[4]?.fieldName]:
          datapackages.data.packages[23]?.name === undefined
            ? ""
            : datapackages.data.packages[23].name,
      },
      {
        [ordersTableExcelLine.uploadDataFields[4]?.fieldName]:
          datapackages.data.packages[24]?.name === undefined
            ? ""
            : datapackages.data.packages[24].name,
      },
      {
        [ordersTableExcelLine.uploadDataFields[4]?.fieldName]:
          datapackages.data.packages[25]?.name === undefined
            ? ""
            : datapackages.data.packages[25].name,
      },
      {
        [ordersTableExcelLine.uploadDataFields[4]?.fieldName]:
          datapackages.data.packages[26]?.name === undefined
            ? ""
            : datapackages.data.packages[26].name,
      },
      {
        [ordersTableExcelLine.uploadDataFields[4]?.fieldName]:
          datapackages.data.packages[27]?.name === undefined
            ? ""
            : datapackages.data.packages[27].name,
      },
      {
        [ordersTableExcelLine.uploadDataFields[4]?.fieldName]:
          datapackages.data.packages[28]?.name === undefined
            ? ""
            : datapackages.data.packages[28].name,
      },
      {
        [ordersTableExcelLine.uploadDataFields[4]?.fieldName]:
          datapackages.data.packages[29]?.name === undefined
            ? ""
            : datapackages.data.packages[29].name,
      },
      {
        [ordersTableExcelLine.uploadDataFields[4]?.fieldName]:
          datapackages.data.packages[30]?.name === undefined
            ? ""
            : datapackages.data.packages[30].name,
      },
      {
        [ordersTableExcelLine.uploadDataFields[4]?.fieldName]:
          datapackages.data.packages[31]?.name === undefined
            ? ""
            : datapackages.data.packages[31].name,
      },
      {
        [ordersTableExcelLine.uploadDataFields[4]?.fieldName]:
          datapackages.data.packages[32]?.name === undefined
            ? ""
            : datapackages.data.packages[32].name,
      },
      {
        [ordersTableExcelLine.uploadDataFields[4]?.fieldName]:
          datapackages.data.packages[33]?.name === undefined
            ? ""
            : datapackages.data.packages[33].name,
      },
      {
        [ordersTableExcelLine.uploadDataFields[4]?.fieldName]:
          datapackages.data.packages[34]?.name === undefined
            ? ""
            : datapackages.data.packages[34].name,
      },
      {
        [ordersTableExcelLine.uploadDataFields[4]?.fieldName]:
          datapackages.data.packages[35]?.name === undefined
            ? ""
            : datapackages.data.packages[35].name,
      },
      {
        [ordersTableExcelLine.uploadDataFields[4]?.fieldName]:
          datapackages.data.packages[36]?.name === undefined
            ? ""
            : datapackages.data.packages[36].name,
      },
      {
        [ordersTableExcelLine.uploadDataFields[4]?.fieldName]:
          datapackages.data.packages[37]?.name === undefined
            ? ""
            : datapackages.data.packages[37].name,
      },
      {
        [ordersTableExcelLine.uploadDataFields[4]?.fieldName]:
          datapackages.data.packages[38]?.name === undefined
            ? ""
            : datapackages.data.packages[38].name,
      },
      {
        [ordersTableExcelLine.uploadDataFields[4]?.fieldName]:
          datapackages.data.packages[39]?.name === undefined
            ? ""
            : datapackages.data.packages[39].name,
      },
    ];
    const validationData = ['Gonzalo', 'Catalina'];
    const validation = {
    ref: 'E2:E10',
    dataValidation: {
      type: 'list',
      formulae: [`"${validationData.join(',')}"`]
    }
  };
    // Crear un objeto WorkBook y agregar una hoja de trabajo
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(data);
    worksheet['!validations'] = [validation];
    const worksheet1 = XLSX.utils.json_to_sheet(data1);
    // Agregar la hoja de trabajo al WorkBook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Formato de Cabecera");
    XLSX.utils.book_append_sheet(workbook, worksheet1, "Maestros");
    // Generar el archivo Excel y descargarlo
    const wbout = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    const blob = new Blob([wbout], { type: "application/octet-stream" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "Formato de Lineas.xlsx";
    link.click();
    URL.revokeObjectURL(url);
  };

  return (
    <div>
      <Button
        type="primary"
        shape="round"
        className="ExcelGeneratorCabecera"
        onClick={() => {
          generateExcelHeader();
        }}
      >
        Generar Excel-Cabeceras
      </Button>
      <Button
        type="primary"
        shape="round"
        className="ExcelGeneratorLinea"
        onClick={() => {
          generateExcelLine();
        }}
      >
        Generar Excel-Lineas
      </Button>
    </div>
  );
};