import axios from 'axios';

const scheme = proto => window.location.protocol === "https:" ? `${proto}s` : proto;

export const getLoadsData = async (args) => {
    try {
        const {
            warehousesname,
            pageSize,
            pageId,
            fromDate,
            toDate,
            searchText,
            seeDispatched,
            seeDelivery
        } = args;

        const endpoint = process.env.REACT_APP_FUNCTION_GET_LOAD_FULL_DATA;

        const bodyLoad = {
        tenantUrl: "navitrans.com.co",
        clientId: "97a8cc5c-65a7-40ac-b1b8-4c9f50e2bc3b",
        clientSecret: "31n8Q~rvYbbRtLqcEhmOob5zhHQPCjQO4611jcZ7",
        tenant: "https://uat3-navitrans.sandbox.operations.dynamics.com",
        environment: "UAT3",
        loadId: searchText === undefined ? "" : searchText,
        inventLocationId: warehousesname?.length === 0 ? "" : warehousesname,
        pageSize,
        pageId,
        isDescending: true,
        fromDate: fromDate ?? "",
        toDate: toDate ?? "",
        seeDispatched: seeDispatched,
        filterByDispatchDate: false
        };

        const response = await axios.post(endpoint, bodyLoad);

        const loads = response.data.response;
        const count = response.data.count;
 
        return {
            loads,
            count
        };

    } catch (error) {
        return error;
    }
};