import React, { useEffect } from "react";
import { useState } from "react";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";

const DetailCarga = ({ loadDetails, shippingDetails }) => {
  const [showLoadDetails, setShowLoadDetails] = useState(true);

  useEffect(() => {
    // Siempre muestra los detalles de la carga al seleccionar una nueva carga
    setShowLoadDetails(true);
  }, [loadDetails]);

  useEffect(() => {
    // Al seleccionar un envío, asegúrate de que se muestren sus detalles
    if (shippingDetails) {
      setShowLoadDetails(false); // Esto asume que quieres mostrar detalles del envío inmediatamente
    }
  }, [shippingDetails]);

  const toggleDetails = () => {
    setShowLoadDetails(!showLoadDetails);
  };

  return (
    <div className="detail-carga">
      <div className="header-detalle-carga">
        <h1 className="titulo">
          {showLoadDetails ? "Detalle de la Carga" : "Detalle del Envío"}
        </h1>
        {shippingDetails && (
          <button onClick={toggleDetails}>
            <SwapHorizIcon className="icon-change" />
          </button>
        )}
      </div>
      {showLoadDetails ? (
        <div className="detail-carga-container">
          <h2 className="numCarga">{loadDetails.LoadId}</h2>
          <h5>Fecha y hora de confirmacion de envio:</h5>
          <p>
            {new Date(loadDetails.LoadShipConfirmUTCDateTime).toLocaleString()}
          </p>
          <h5>Bodega:</h5>
          <p>{loadDetails.InventLocationId}</p>
          <h5>Orden/TRN:</h5>
          <p>{loadDetails.OrderNum}</p>
          <h5>Nombre Asesor:</h5>
          <p>{loadDetails.WorkerSalesResponsible}</p>
          <h5>Transportista:</h5>
          <p>{loadDetails.CarrierCode}</p>
          <h5>Servicio:</h5>
          <p>{loadDetails.CarrierServiceCode}</p>
          <h5>Modo de entrega:</h5>
          <p>{loadDetails.DlvMode}</p>
          <h5>Forma de pago:</h5>
          <p>{loadDetails.PaymentMode}</p>
          <h5>Valor a pagar contraentrega:</h5>
          <p>{loadDetails.PaymentOnCashValue}</p>
          <h5>Moneda:</h5>
          <p>{loadDetails.PaymentOnCashValueCurrency}</p>
          <h5>Facturas:</h5>
          <p>{loadDetails.InvoiceQty}</p>
          <h5>Remisiones:</h5>
          <p>{loadDetails.SlipShippingQty}</p>
          <h5>Lineas:</h5>
          <p>{loadDetails.LinesQty}</p>
          <h5>Contenedores:</h5>
          <p>{loadDetails.ContainersQty}</p>
        </div>
      ) : (
        shippingDetails?.ShipmentId !== undefined && (
          <div className="detail-carga-container">
            <h2 className="numCarga">{shippingDetails.ShipmentId}</h2>
            <h5>Almacen Destino:</h5>
            <p>
              {shippingDetails.InventLocationIdTo
                ? shippingDetails.InventLocationIdTo
                : "N/D"}
            </p>
            <h5>Orden/TRN:</h5>
            <p>{shippingDetails.OrderNum ? shippingDetails.OrderNum : "N/D"}</p>
            <h5>Factura:</h5>
            <p>
              {shippingDetails?.InvoiceList?.[0]?.InvoiceId
                ? shippingDetails?.InvoiceList?.[0]?.InvoiceId
                : "N/D"}
            </p>
            <h5>Remision:</h5>
            <p>
              {shippingDetails?.ItemList?.[0]?.PackingSlipId
                ? shippingDetails?.ItemList?.[0]?.PackingSlipId
                : "N/D"}
            </p>
            <h5>Cuenta Cliente:</h5>
            <p>
              {shippingDetails.CustAccount
                ? shippingDetails.CustAccount
                : "N/D"}
            </p>
            <h5>Nombre Cliente:</h5>
            <p>
              {shippingDetails.SalesName ? shippingDetails.SalesName : "N/D"}
            </p>
            <h5>Cuenta Proveedor:</h5>
            <p>{shippingDetails.OrderAccount}</p>
            <h5>Nombre Proveedor:</h5>
            <p>{shippingDetails.PurchName}</p>
            <h5>Departamento:</h5>
            <p>{shippingDetails.State}</p>
            <h5>Ciudad:</h5>
            <p>{shippingDetails.City}</p>
            <h5>Direccion:</h5>
            <p>{shippingDetails.Street}</p>
            <h5>Complemento:</h5>
            <p>{shippingDetails.BuildingCompliment}</p>
            <h5>Nombre Contacto:</h5>
            <p>{shippingDetails.ContactName}</p>
            <h5>Telefono Contacto:</h5>
            <p>{shippingDetails.ContactPhone}</p>
            <h5>Email Contacto:</h5>
            <p>{shippingDetails.ContactEmail}</p>
            <h5>Forma de Pago:</h5>
            <p>{shippingDetails?.InvoiceList?.[0]?.Payment}</p>
            <h5>Moneda:</h5>
            <p>{shippingDetails?.InvoiceList?.[0]?.CurrencyCode ? shippingDetails?.InvoiceList?.[0]?.CurrencyCode: "N/D"}</p>
            <h5>Valor a Pagar Contraentrega:</h5>
            <p>{shippingDetails.PaymentOnCashValue}</p>
          </div>
        )
      )}
    </div>
  );
};

export default DetailCarga;
