import React from "react";

const Lineas = ({ lines }) => {
  console.log(lines);

  return (
    <div>
      {lines.map((line, index) => (
        <div key={index.ItemId} className="line-card">
          <div className="line-titulo">
            <h4>Codigo</h4>
          </div>
          <div className="line-id">
            <h2>{line.ItemId}</h2>
          </div>
          <div className="line-name">
            <h2>{line.ProductName}</h2>
          </div>
          <div className="line-detalles">
            <div className="line-detalles-column">
              <h5>Cantidad</h5>
              <p>{line.ProductQuantity}</p>
            </div>
            <div className="line-detalles-column">
              <h5>FACTURA</h5>
              <p>{line.InvoiceId}</p>
            </div>
            <div className="line-detalles-column">
              <h5>REMISION</h5>
              <p>{line.PackingSlipId}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Lineas;
