import React, { useState, useEffect } from 'react';
import { Card, Table, Tag, Alert, Input, Button, Space, Row, Col } from 'antd';
import EllipsisText from 'react-ellipsis-text';
import Highlighter from 'react-highlight-words';
import { SearchOutlined, LeftOutlined, ClearOutlined, ArrowUpOutlined,SendOutlined, HistoryOutlined,FieldTimeOutlined } from '@ant-design/icons';
import Icon from '@ant-design/icons';
import {ToTopOutlined, DownloadOutlined, ShoppingCartOutlined, CarOutlined}from '@ant-design/icons';
import { useScheduling } from '../../context/SchedulingContext';
import {DeleteOrders} from "./deleteOrders";
import Swal from 'sweetalert2';
import moment from 'moment';
import 'moment/locale/es';
import { useLazyQuery } from "@apollo/client";
import loggerCooltrack from '../../../../services/logger-cooltrack';
import localStorageService from "../../../../services/localStorageService";

const role = localStorageService.get("role");

export const OrdersList = (props) => {
    const {
        fetching,
        orders,
        setToLeftButtonDisabled, 
        setSelectedRightRow,
        toLeftButtonDisabled,
        selectedUserVehicle,
        onClickToLeftButton,
        onClickDeleteToLeftButton
    } = useScheduling();

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [searchInput, setSearchInput] = useState({});
    const [selectAll, setSelectAll] = useState(false);
    const [showDrawer, setShowDrawer] = useState(false);

    const handleNew = () => {
        setShowDrawer(true);
      
    };

    useEffect(() => {
        if(props.selectedRows.length > 0) {
            setToLeftButtonDisabled(false);
            setSelectedRightRow(props.selectedRows);
        } else {
            setToLeftButtonDisabled(true);
            setSelectedRightRow([]);
        }

    }, [props.selectedRows]);


    const MuliselectIconSVG = () => (

        <svg 
            width="1em" 
            height="1em" 
            fill="currentColor" 
            viewBox="0 0 264 571">
        
            <path d="M16.45,129.41c9.91-8,19.82-16,32-25.87l38,41.57,128-128.34,28.14,30.55L86.33,203.51,16.45,136.57Z"/>
            <path d="M16.45,297.14l32-25.87,38,41.57,128-128.34L242.56,215,86.32,371.23,16.45,304.3Z"/>
            <path d="M16,464.71l32-25.87,38,41.58L214,352.07l28.09,30.5L85.87,538.81,16,471.87Z"/>
        </svg>
    );


    const getColumnSearchProps = (title, dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters
        }) => (
            <div style={{ padding: 8}}>
                <Input
                    ref={node => {
                        setSearchInput(node);
                    }}
                    placeholder={`Buscar ${title}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Buscar
                    </Button>
                    <Button
                        onClick={() => {
                            props.setSelectedRowKeys([]);
                            props.setSelectedRows([]);
                            handleReset(clearFilters)}
                        }
                        size="small"
                        style={{ width: 30 }}
                        icon={<ClearOutlined />}
                    />
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                if(Object.keys(searchText).length > 0) {
                    setTimeout(() => searchInput.select(), 100);
                }
            }
        },
        render: text =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
          ) : (
                text
          ),
    });   
    
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex)
    };
    
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const format_number = str => {
        str += '';
        let x = str.split('.');
        let x1 = x[0];
        let x2 = x.length > 1 ? '.' + x[1] : '';
        let rgx = /(\d+)(\d{3})/;
        while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ',' + '$2');
        }
        return x1 + x2;
    };

    const columns = [
        {
            //fixed: 'left',
            title: 'Zona',
            dataIndex: 'color',
            key: 'color',
            align: 'center',
            width: 70,
            ...getColumnSearchProps('zona', 'sector'),
            render: (color) => <Tag color={color} style={{borderRadius: '10px', height: '1em'}} />
        },
        {
            title: '# Orden',
            dataIndex: 'orderNumber',
            key: 'orderNumber',
            align: 'center',
            width: 100,
            ...getColumnSearchProps('orden', 'orderNumber'),
            render: (text) => <small>{text}</small>,
        },
        {
            title: 'Dirección',
            dataIndex: 'address',
            key: 'address',
            ...getColumnSearchProps('dirección', 'address'),
            render: (text) => <small><EllipsisText text={text ?? ''} length={25} /></small>,
        },
        {
            title: 'Peso',
            dataIndex: 'weight',
            key: 'weight',
            align: 'center',
            width: 70,
            render: (value) => <small>{value.toLocaleString('es')} kgs</small>
        },
        {
            title: 'Prioridad',
            dataIndex: 'priority',
            key: 'priority',
            align: 'center',
            width: 100,
            ...getColumnSearchProps('prioridad', 'priority'),
            render: (value) => <small>{value.toLocaleString('es')}</small>,
        },
        {
            title: 'Reprogramado',
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            width: 100,
            ...getColumnSearchProps('Reprogramado', 'status'),
            render: (value) => <small>{value.toLocaleString('es')}</small>,
        },
    ];

    return (
        <>
        
        <p className="scheduling-screen-list-container11-title">Ordenes ({`${props.selectedRowKeys.length}/${orders.length}`})</p>
        <Col span={22} className="orders-list-screen-list-container11">
        
        {
            (props.filter.length > 0 && props.activeFilter3 ? orders.filter(item=>{
               
                if((item.orderNumber).toUpperCase().includes((props.filter).toUpperCase())){
                    return true;
                }

                if((item.address).toUpperCase().includes((props.filter).toUpperCase())){
                    return true;
                }

                if((item.addressComplement).toUpperCase().includes((props.filter).toUpperCase())){
                    return true;
                }

                if((item.custFullName).toUpperCase().includes((props.filter).toUpperCase())){
                    return true;
                }

                if((item.custIdentificationNumber).toUpperCase().includes((props.filter).toUpperCase())){
                    return true;
                }

                if((item.sector).toUpperCase().includes((props.filter).toUpperCase())){
                    return true;
                }
                if((item.priority).toUpperCase().includes((props.filter).toUpperCase())){
                    return true;
                }
                if((item.route).toUpperCase().includes((props.filter).toUpperCase())){
                    return true;
                }
                if((item.type).toUpperCase().includes((props.filter).toUpperCase())){
                    return true;
                }
                return false;
            }) : orders).map((item, index) => {  
                return <Row key={index} gutter={[0, 0]} className={`${item.enableDeliveryWindow ? "orders-list-screen-list-item-row-container-time-window-order" : "orders-list-screen-list-item-row-container"} ${props.selectedRowKeys.filter(filterItem => filterItem === item.id).length > 0 ? 'orders-list-screen-list-item-row-container-selected' : ''}`} onClick={() => {
                      console.log(props.selectedRows.length)
                        if(props.selectedRowKeys.filter(filterItem => filterItem === item.id).length > 0){
                            
                            for (let i = 0; i < props.selectedRows.length; i++) {
                                const element = props.selectedRows[i];
                                
                                const isEqual = (element.id === item.id);

                                if(isEqual){
                                    props.removeItem(element);
                                }
                            }
                            
                        }else{
                            props.addItem(item);
                        }
                    }}>
                    <Col span={24}>
                        <Row gutter={[0, 0]}>
                            <Col span={11}>
                                <Row className="orders-list-screen-list-item-label-1">{(item.orderNumber || 'n/a').toUpperCase()}</Row>
                                <Row className="orders-list-screen-list-item-label-2">{item.address === null ? "" : item.address} {item.addressComplement === null ? "" : item.addressComplement}</Row>
                                <Row className="orders-list-screen-list-item-label-3">{item.custFullName === null ? "" : item.custFullName}</Row>
                                <Row className="orders-list-screen-list-item-label-23">{item.custPhoneNumber === null ? "" : item.custPhoneNumber}</Row>
                                {item.consecutiveBurden === null || item.consecutiveBurden === "" ? "": <Row className="orders-list-screen-list-item-label-25">Carga: {item.consecutiveBurden}</Row>}
                                <Row className="orders-list-screen-list-item-label-26">Almacen: {item.distributionCenter === null ? "" : item.distributionCenter}</Row>
                            </Col>
                            <Col span={11}>
                                                              
                                {
                                     item.consecutiveBill === null || item.consecutiveBill === "" ? "": <Row className="orders-list-screen-list-item-label-20">Factura{" "}{item.consecutiveBill.split(',   ').map((part, index) => 
                                     (
                                         <div key={index}>
                                           {part}
                                           {index < item.consecutiveBill.split(',').length - 1 && <br />}
                                         </div>
                                       ))}
                                     </Row>
                                    
                                }
                                {
                                    item.consecutiveRemission === null || item.consecutiveRemission === "" ? "": <Row className="orders-list-screen-list-item-label-21">Remisión:{" "}{item.consecutiveRemission.split(',').map((part, index) => 
                                    (
                                        <div key={index}>
                                          {part}
                                          {index < item.consecutiveRemission.split(',').length - 1 && <br />}
                                        </div>
                                      ))}
                                    </Row>
                                }
                                
                             {/*                        
                                {
                                    item.consecutiveBill === null || item.consecutiveBill === "" ? "": <Row className="orders-list-screen-list-item-label-20">Factura: {item.consecutiveBill}</Row>
                                    
                                }
                                {
                                    item.consecutiveRemission === null || item.consecutiveRemission === "" ? "": <Row className="orders-list-screen-list-item-label-21">Remisión: {item.consecutiveRemission}</Row>
                                }
                            */}
                                {
                                    item.consecutiveSaleOrder === null || item.consecutiveSaleOrder === "" ? "": <Row className="orders-list-screen-list-item-label-22">Orden: {item.consecutiveSaleOrder}</Row>
                                }
                                <Row className="orders-list-screen-list-item-label-4">{item.weight === null ? "" : format_number(item.weight)}Kg ({item.totalCubicMeters === null ? "" :format_number(item.totalCubicMeters)} M3)</Row>
                                <Row className="orders-list-screen-list-item-label-5"><div className="orders-list-screen-list-state" style={{backgroundColor: item.color, width: '12px', height: '12px', borderRadius: '4px', marginRight: '4px', marginTop: '2px'}}></div>{item.sector === null ? "" : item.sector}</Row>
                                <Row className="orders-list-screen-list-item-label-7">Prioridad: {item.priority === "a_high" ? "Alto" : item.priority === "b_medium" ? "Medio" : item.priority === "c_low" ? "Bajo" : ""}</Row>
                                <Row className="orders-list-screen-list-item-label-80">Fecha de Creación: {moment(item.dateCreate).format("YYYY-MM-DD HH:mm A")}</Row>
                                <Row >{
                                item.route === "rescheduled_delivery" ? (
                                        <>
                                        <HistoryOutlined className="orders-list-screen-list-item-label-8" />
                                        <span className="orders-list-screen-list-item-label-51">Reprogramado</span>
                                        </>
                                    ) : item.route === "partial_delivered" ? (
                                        <>
                                        <FieldTimeOutlined className="orders-list-screen-list-item-label-50" />
                                        <span className="orders-list-screen-list-item-label-51">Con Novedad</span>
                                        </>
                                    ) : (
                                        ""
                                    )
                                }</Row>
                            </Col>
                            <Col span={2}>
                                <Row className="orders-list-screen-list-item-label-6">{index + 1}</Row>
                                <Row className="orders-list-screen-list-item-label-9">{item.type === "delivery" ? <ToTopOutlined /> : item.type === "pickup" ? <DownloadOutlined /> : item.type === "customService" ? <ShoppingCartOutlined /> : item.type === "warehousesTransfer" ? <CarOutlined /> : ""}</Row>
                            </Col>
                        </Row>
                        {item.enableDeliveryWindow &&
                        <Row gutter={[0, 0]}>
                        <Col span={24}>
                            <Row className="orders-list-screen-list-item-label-12">Fecha de compromiso de entrega</Row>
                            <Row gutter={[0, 0]}>
                                <Col span={12}>
                                    <Row className="orders-list-screen-list-item-label-10">{item.expectedDeliveryStartTimee === null ? "" : moment(item.expectedDeliveryStartTimee).format("YYYY-MM-DD HH:mm A")}</Row>
                                </Col>
                                <Col span={12}>
                                    <Row className="orders-list-screen-list-item-label-10">{item.expectedDeliveryEndTime === null ? "" : moment(item.expectedDeliveryEndTime).format("YYYY-MM-DD HH:mm A")}</Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                        
                    }
                    </Col>
                </Row>
            }
            )
        }
        <Row gutter={[0, 0]} style={{textAlign: 'center', position: 'absolute', width: 'Calc(100% - 16px)', margin: '8px', bottom: 0}}>
            <Col span={24}></Col>
        </Row>
        </Col>
        <Col span={2} className="scheduling-screen-list-button-container">
        <div style={{height: '160px'}}>
            <Button className="scheduling-screen-list-button-init" disabled={props.selectedRows.length === 0 || selectedUserVehicle.userId === undefined} type="primary" icon={<ArrowUpOutlined />} onClick={
                () => {
                    onClickToLeftButton();
                    props.clearOrders();
                    setSelectAll(false);
                }
            }/>

            <Button className="scheduling-screen-list-button-init" 
            disabled={role === "user" || props.selectedRows.length !== 1 || props.selectedRows[0]?.route === "partial_delivered"} 
            type="primary" 
            icon={<SendOutlined />} 
            onClick={
                () => {
                    handleNew()
                }
            }/>
            <Button className="scheduling-screen-list-button-end" disabled={(props.filter.length > 0 && props.activeFilter3 ? orders.filter(item=>{
                
                if((item.orderNumber).toUpperCase().includes((props.filter).toUpperCase())){
                    return true;
                }

                if((item.address).toUpperCase().includes((props.filter).toUpperCase())){
                    return true;
                }

                if((item.addressComplement).toUpperCase().includes((props.filter).toUpperCase())){
                    return true;
                }

                if((item.custFullName).toUpperCase().includes((props.filter).toUpperCase())){
                    return true;
                }

                if((item.custIdentificationNumber).toUpperCase().includes((props.filter).toUpperCase())){
                    return true;
                }

                if((item.sector).toUpperCase().includes((props.filter).toUpperCase())){
                    return true;
                }
                if((item.priority).toUpperCase().includes((props.filter).toUpperCase())){
                    return true;
                }
                
                return false;
            }) : orders).length === 0} type="primary" icon={<Icon component={MuliselectIconSVG}/>} onClick={
                () => {
                    if(!selectAll){
                        props.addAll((props.filter.length > 0 && props.activeFilter3 ? orders.filter(item=>{
                
                if((item.orderNumber).toUpperCase().includes((props.filter).toUpperCase())){
                    return true;
                }

                if((item.address).toUpperCase().includes((props.filter).toUpperCase())){
                    return true;
                }

                if((item.addressComplement).toUpperCase().includes((props.filter).toUpperCase())){
                    return true;
                }

                if((item.custFullName).toUpperCase().includes((props.filter).toUpperCase())){
                    return true;
                }

                if((item.custIdentificationNumber).toUpperCase().includes((props.filter).toUpperCase())){
                    return true;
                }

                if((item.sector).toUpperCase().includes((props.filter).toUpperCase())){
                    return true;
                }
                if((item.priority).toUpperCase().includes((props.filter).toUpperCase())){
                    return true;
                }
                if((item.consecutiveBill).toUpperCase().includes((props.filter).toUpperCase())){
                    return true;
                }
                if((item.consecutiveRemission).toUpperCase().includes((props.filter).toUpperCase())){
                    return true;
                }
                if((item.consecutiveSaleOrder).toUpperCase().includes((props.filter).toUpperCase())){
                    return true;
                }
                
                return false;
            }) : orders));
                        setSelectAll(true);
                    }else{
                        props.clearOrders();
                        setSelectAll(false);                
                    }
                }
            }/>
        </div>
        </Col>
        {showDrawer === true ? (
        <DeleteOrders 
            Order = {props.selectedRows}
            showDrawer ={showDrawer}
            setShowDrawer={setShowDrawer} 
        />):(<></>)
        }
        </>
    );
};