import React from "react";
import imageStatus from "../../../../../../../images/truck.png";
import "../../stylesTMS/styles-tms.css";
import TruncatedTextWithTooltip from "./Tooltip";
const LoadCard = ({ carga, onClick, isSelected }) => {
  return (
    <div
      className={`tarjeta-carga ${isSelected ? "selected" : ""}`}
      onClick={onClick}
    >
      <div className="tarjeta-header-padre">
        <h4>Carga</h4>
        <h3>{carga.LoadStatus}</h3>
      </div>
      <div className="tarjeta-header">
        <h2>{carga.LoadId}</h2>
        <h3>{carga.Type}</h3>
      </div>
      <div className="tarjeta-info">
        <div className="tarjeta-detalles">
          <div className="tarjeta-detalles-column">
            <h5>Fecha y hora de envio:</h5>
            <p>{new Date(carga.LoadShipConfirmUTCDateTime).toLocaleString()}</p>
          </div>
          <div className="tarjeta-detalles-column">
            <h5>Bodega:</h5>
            <p>{carga.InventLocationId ? carga.InventLocationId : "N/D"}</p>
          </div>
          <div className="tarjeta-detalles-column">
            <h5>Orden/TRN:</h5>
            <p>{carga.OrderNum ? carga.OrderNum : "N/D"}</p>
          </div>
          <div className="tarjeta-detalles-column">
            <h5>Nombre Asesor:</h5>
            <TruncatedTextWithTooltip
              text={
                carga.WorkerSalesResponsible
                  ? carga.WorkerSalesResponsible
                  : "N/D"
              }
              maxLength={15}
            />
          </div>
          <div className="tarjeta-detalles-column">
            <h5>Transportista:</h5>
            <p>{carga.CarrierCode ? carga.CarrierCode : "N/D"}</p>
          </div>
          <div className="tarjeta-detalles-column">
            <h5>Servicio:</h5>
            <p>{carga.CarrierServiceCode ? carga.CarrierServiceCode : "N/D"}</p>
          </div>
          <div className="tarjeta-detalles-column">
            <h5>Modo de entrega:</h5>
            <p>{carga.DlvMode ? carga.DlvMode : "N/D"}</p>
          </div>
          <div className="tarjeta-detalles-column">
            <h5>Forma de pago:</h5>
            <p>{carga.PaymentMode ? carga.PaymentMode : "N/D"}</p>
          </div>
          <div className="tarjeta-detalles-column">
            <h5>Valor a pagar contraentrega:</h5>
            <p>{carga.PaymentOnCashValue ? carga.PaymentOnCashValue : "0"}</p>
          </div>
          <div className="tarjeta-detalles-column important">
            <h5>Moneda:</h5>
            <p>{carga.PaymentOnCashValueCurrency}</p>
          </div>
          <div className="tarjeta-detalles-column important">
            <h5>Facturas:</h5>
            <p>{carga.InvoiceQty}</p>
          </div>
          <div className="tarjeta-detalles-column important">
            <h5>Remisiones:</h5>
            <p>{carga.SlipShippingQty}</p>
          </div>
          <div className="tarjeta-detalles-column important">
            <h5>Lineas:</h5>
            <p>{carga.LinesQty}</p>
          </div>
          <div className="tarjeta-detalles-column important">
            <h5>Contenedores:</h5>
            <p>{carga.ContainersQty}</p>
          </div>
        </div>
        <div className={`image-estatus ${isSelected ? "selected-status" : ""}`}>
          <img src={imageStatus} />
        </div>
      </div>
    </div>
  );
};

export default LoadCard;
