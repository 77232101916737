import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useApolloClient } from "@apollo/client";
import { Form, Button, Checkbox } from "antd";
import MailIcon from "@mui/icons-material/Mail";
import PasswordIcon from "@mui/icons-material/Lock";
import Swal from "sweetalert2";
import axios from "axios";

import "../styles/login.css";

import loggerCooltrack from "../../../../services/logger-cooltrack";
import CustomInput from "../../../custom-components/input/CustomInput";
import localStorageService from "../../../../services/localStorageService";

export const LoginScreen = () => {
  const client = useApolloClient();
  //const client = apiClient.apiClient;
  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    localStorageService.remove("token");
    localStorageService.remove("id");
    localStorageService.remove("role");
    localStorageService.remove("userEmail");
    const { email, password, remember } = values;

    setLoading(true);

    try {
      axios({
        method: "POST",
        url: process.env.REACT_APP_FUNCTION_LOGIN,
        data: {
          email,
          password,
        },
      })
        .then((res) => {
          if (res.status === 200) {
            const user = res.data;
            
            localStorageService.set("role", user.role.name);
            localStorageService.set("id", user.id);
            localStorageService.set("userEmail", user.email);
            localStorageService.set(
              "userDistributionCenterId",
              user.userDistributionCenter.id
            );
            localStorageService.set(
              "userDistributionCenterName",
              user.userDistributionCenter.name
            );
            localStorageService.set(
              "selectedWarehouses",
              JSON.stringify({
                warehouses: [user.userDistributionCenter.id],
              })
            );
            localStorageService.set(
              "selectedWarehousesname",
              JSON.stringify({
                warehousesname: [user.userDistributionCenter.name],
              })
            );
            const userDeliveryCenters = user.userDeliveryCenters;
            const userDistributionsCenters = userDeliveryCenters.map(
              (item) => item.distributionCenter[0].id
            );
            localStorageService.set(
              "userDistributionsCenters",
              JSON.stringify(userDistributionsCenters)
            );
            const userDistributionsCentersName = userDeliveryCenters.map(
              (item) => item.distributionCenter[0].name
            );
            localStorageService.set(
              "userDistributionsCentersName",
              JSON.stringify(userDistributionsCentersName)
            );
            localStorageService.set("companyId", user.companyId);
            localStorageService.set("company", user.userCompany.name);
            localStorageService.set(
              "companyDetails",
              JSON.stringify({
                Status: user.userCompany.enabled.toString(),
                primaryColor: user.userCompany.primaryColor,
                smallBrand: user.userCompany.smallBrand,
                bigBrand: user.userCompany.bigBrand,
              })
            );

            fetch(process.env.REACT_APP_FUNCTION_ACCESS_TOKEN, {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
                id: user.id,
                role: user.role.name,
              }),
            }).then(async (res) => {
              const token = await res.json();
              
              localStorageService.set("token", token.accessToken);

              if (remember) {
                localStorage.setItem(
                  "userData",
                  JSON.stringify({
                    id: user.id,
                    displayName: user.displayName,
                    email: user.email,
                    identificatioNumber: user.identificatioNumber,
                    phoneNumber: user.phoneNumber,
                    photoURL: user.photoURL,
                    roleName: user.role.name,
                    companyId: user.companyId,
                    company: user.userCompany.name,
                  })
                );
                saveSesionStorage(user);
              } else {
                saveSesionStorage(user);
              }

              loggerCooltrack({
                module: "Login",
                operation: "signIn",
                target: user.companyId,
              });

              window.location.reload();
            });
          } else {
            setLoading(false);
            Swal.fire({
              icon: "error",
              title: "Error",
              text: `Error: ${res.data.statusText}`,
              showConfirmButton: false,
              timer: 5000,
            });
          }
        })
        .catch((error) => {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `Error: ${error.toString()}`,
            showConfirmButton: false,
            timer: 5000,
          });
        });
    } catch (error) {
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Error: ${error.toString()}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  };

  const saveSesionStorage = (user) => {
    sessionStorage.setItem(
      "userData",
      JSON.stringify({
        id: user.id,
        displayName: user.displayName,
        email: user.email,
        identificatioNumber: user.identificatioNumber,
        phoneNumber: user.phoneNumber,
        photoURL: user.photoURL,
        roleName: user.role.name,

        companyId: user.companyId,
        company: user.userCompany.name,
      })
    );
  };

  return (
    <div className="login-wrap">
      <Form
        autoComplete="chrome-off"
        className="login-form"
        name="login"
        initialValues={{ remember: false }}
        onFinish={onFinish}
        //onFinishFailed={onFinishFailed}
      >
        <div className="login-background-brand-sm"></div>
        <span className="login-form-title">Ingresar</span>
        <span className="login-form-subtitle">
          Por favor ingresa los datos de tu cuenta
        </span>
        <Form.Item
          required
          name="email"
          hasFeedback
          rules={[
            {
              type: "email",
              message: "Correo electrónico no válido",
            },
            {
              required: true,
              message: "Ingrese correo electrónico",
            },
          ]}
          initialValue={undefined}
        >
          <CustomInput
            autoFocus={true}
            placeholder="Correo electrónico"
            prefix={<MailIcon />}
          />
        </Form.Item>

        <Form.Item
          required
          name="password"
          hasFeedback
          rules={[
            {
              required: true,
              message: "Por favor ingrese la contraseña!",
            },
            ({ _ }) => ({
              validator(_, value) {
                if (value?.length < 6) {
                  return Promise.reject("Mínimo 6 caracteres");
                }

                return Promise.resolve();
              },
            }),
          ]}
          initialValue={undefined}
        >
          <CustomInput
            isPassword={true}
            placeholder="Contraseña"
            prefix={<PasswordIcon />}
          />
        </Form.Item>
        <div className="flex-sb-m w-full p-t-3 p-b-32">
          <div>
            <Form.Item name="remember" valuePropName="checked">
              <Checkbox>Recuérdame</Checkbox>
            </Form.Item>
          </div>

          <div>
            <Link to="/reset-password" className="reset-password">
              Olvidé mi contraseña
            </Link>
          </div>
        </div>
        <Form.Item>
          <Button
            loading={loading}
            className="login-submit"
            type="primary"
            htmlType="submit"
            size="large"
          >
            Ingresar
          </Button>
        </Form.Item>
      </Form>
      {/* <div className="login-background" style={{ backgroundImage: "url('https://res.cloudinary.com/productsview/image/upload/v1666982995/SRF_Map.jpg')"}}>
                    <div className='login-background-brand'></div>
            </div> */}
    </div>
  );
};
