import axios from 'axios';

const scheme = proto => window.location.protocol === "https:" ? `${proto}s` : proto;

export const getLoadsData = async (args) => {
    try {
        const {
            warehousesname,
            pageSize,
            pageId,
            fromDate,
            toDate,
            searchText,
            seeDispatched,
            seeDelivery,
            transport,
            ovTrn
        } = args;

        const endpoint = process.env.REACT_APP_FUNCTION_GET_LOAD_FULL_DATA;

        const bodyLoad = {
        tenantUrl: "navitrans.com.co",
        clientId: "97a8cc5c-65a7-40ac-b1b8-4c9f50e2bc3b",
        clientSecret: "Iwn8Q~aK8zD83KUsmXUABb8l_eRJ6G~oOzYCgcRB",
        tenant: "https://fenix365.operations.dynamics.com",
        environment: "FENIX365",
        loadId: searchText === undefined ? "" : searchText,
        inventLocationId: warehousesname?.length === 0 ? "" : warehousesname,
        pageSize,
        pageId,
        isDescending: true,
        fromDate: fromDate ?? "",
        toDate: toDate ?? "",
        seeDispatched: seeDispatched,
        filterByDispatchDate: false,
        transport: transport === undefined ? "" : transport,
        ovTrn: ovTrn === undefined ? "" : ovTrn,
        };

        const response = await axios.post(endpoint, bodyLoad);

        const loads = response.data.response;
        const count = response.data.count;
 
        return {
            loads,
            count
        };

    } catch (error) {
        return error;
    }
};