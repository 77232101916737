import React, { useState} from "react";
import { TMSSearchText } from "./tms-filters-search-text";


export const TMSFiltersCarga = ({isBlankActive, handleClearFilters}) => {
  const [inputValue, setInputValue] = useState(isBlankActive);
    return (
        <TMSSearchText isBlankActive={isBlankActive}/> 
  );
};
