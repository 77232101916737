import { gql } from '@apollo/client';

export const releaseOrdersQuery = gql`
query releaseOrdersQuery {
    historicalDeleteOrders {
      id
      name
      identificationNumber
      type
      coment
      User{
        email
        displayName
        identificationNumber
      }
      DeleteOrdersTable {
        created_at
        orderNumber
        addressComplement
        consecutiveBill
        consecutiveBurden
        consecutiveRemission
        consecutiveSaleOrder
        custEmail
        custFullName
        custIdentificationNumber
        custPhoneNumber
        destination
        distributionCenterid
        enableDeliveryWindow
        priority
        type
        totalCubicMeters
        weight
        DeleteOrdersLine{
          Invoice
          PackingSlip
          cubicMeters
          deliveredQuantity
          depth
          height
          externalId
          externalInvoiceId
          externalSalesId
          initialQuantity
          numberLine
          orderNumber
          orderedQuantity
          packageId
          partWeight
          partsPerBox
          processed
          productName
          productNumber
          safetyFactor
          summationQuantity
          totalCubicMeters
          weight
          width
        }
      }
    }
}
`;