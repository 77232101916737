import React, { useEffect } from "react";
import { Tab, Tabs, Box } from "@mui/material";
import Map from "./Map";
import Lineas from "./Lineas";

const InfoEnvios = ({
  lines,
  onMapTabSelect,
  selectedShipping,
  isMapModalOpen,
}) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    onMapTabSelect(newValue === 2);
  };

  useEffect(() => {
    setValue(0);
  }, [lines, isMapModalOpen]);

  return (
    <Box sx={{ width: "100%" }}>
      <Tabs value={value} onChange={handleChange} centered className="Tabs">
        <Tab label="LINEAS" />
        <Tab label="CONTENEDORES" />
        <Tab label="MAPA" />
      </Tabs>
      {value === 0 && <Lineas lines={lines} />}
      {value === 1 && <div>CONTENEDORES</div>}
      {value === 2 && !isMapModalOpen && (
        <Map
          address={`${selectedShipping.Street}, ${selectedShipping.City}, ${selectedShipping.State}`}
        />
      )}
    </Box>
  );
};

export default InfoEnvios;
