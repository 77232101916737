import React, { useState } from "react";

const TruncatedTextWithTooltip = ({ text, maxLength }) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const handleMouseEnter = () => {
    if (text.length > maxLength) {
      setIsTooltipVisible(true);
    }
  };

  const handleMouseLeave = () => {
    setIsTooltipVisible(false);
  };

  const truncatedText = text?.length > maxLength ? text.slice(0, maxLength) + '...' : text;

  return (
    <div className="truncated-text" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <span>{truncatedText}</span>
      {isTooltipVisible && <div className="tooltip">{text}</div>}
    </div>
  );
};

export default TruncatedTextWithTooltip;