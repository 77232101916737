import React, { useEffect, useState } from "react";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment"; // maneja fechas con moment.js
import { TextField } from "@material-ui/core";
import { useTMSStore } from "../../../../../../../store/tms-store";

export const TMSSearchDate = ({ isBlankActive }) => {
  const setSearchFromDate = useTMSStore((state) => state.setSearchFromDate);
  const setSearchToDate = useTMSStore((state) => state.setSearchToDate);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [inputValue, setInputValue] = useState(isBlankActive);

  useEffect(() => {
    setInputValue(isBlankActive);
  }, [isBlankActive]);

  const handleFromDateChange = (date) => {
    // Formateo de la fecha en formato si existe, sino se setea a null
    const formattedDate = date ? date.format("YYYY-MM-DD") : null;
    setFromDate(formattedDate);
    setSearchFromDate(formattedDate);
    console.log("Desde:", formattedDate);
  };

  const handleToDateChange = (date) => {
    const formattedDate = date ? date.format("YYYY-MM-DD") : null;
    setToDate(formattedDate);
    setSearchToDate(formattedDate);
    console.log("Hasta:", formattedDate);
  };

  return (
    <div className="tms-search-date-container">
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <KeyboardDatePicker
          disableToolbar
          variant="inline"
          format="YYYY-MM-DD"
          margin="normal"
          label="Desde"
          value={fromDate}
          onChange={handleFromDateChange}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
          className="tms-search-textfield-date"
        />
        <KeyboardDatePicker
          disableToolbar
          variant="inline"
          format="YYYY-MM-DD"
          margin="normal"
          label="Hasta"
          value={toDate}
          onChange={handleToDateChange}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
          className="tms-search-textfield-date"
        />
      </MuiPickersUtilsProvider>
    </div>
  );
};
