// locationService.js

// Función para obtener los departamentos
export const getDepartments = async () => {
    try {
      const response = await fetch('https://www.datos.gov.co/resource/xdk5-pm3f.json?');
      const data = await response.json();
      const departments = [...new Set(data.map(item => item.departamento))]; // Elimina duplicados
      return departments;
    } catch (error) {
      console.error('Error fetching departments:', error);
      return [];
    }
  };
  
  // Función para obtener las ciudades de un departamento específico
  export const getCitiesByDepartment = async (departmentName) => {
    try {
      const response = await fetch(`https://www.datos.gov.co/resource/xdk5-pm3f.json?departamento=${departmentName}`);
      const data = await response.json();
      const cities = data.map(item => item.municipio);
      return cities;
    } catch (error) {
      console.error(`Error fetching cities for department ${departmentName}:`, error);
      return [];
    }
  };