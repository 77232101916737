import React, { useEffect } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const geocodeAddress = async (address, city, state, apiKey) => {
  // Intenta primero con la dirección completa
  let formatAddress = address.replace(/ /g, "+");
  let url = `https://maps.googleapis.com/maps/api/geocode/json?address=${formatAddress}&key=${apiKey}`;

  try {
    let response = await fetch(url);
    let data = await response.json();
    if (data.status === "OK" && data.results[0]) {
      return data.results[0].geometry.location;
    } else {
      // Si la dirección completa no funciona, intenta con ciudad y departamento
      formatAddress = `${city.replace(/ /g, "+")},+${state.replace(/ /g, "+")}`;
      url = `https://maps.googleapis.com/maps/api/geocode/json?address=${formatAddress}&key=${apiKey}`;
      response = await fetch(url);
      data = await response.json();
      if (data.status === "OK" && data.results[0]) {
        return data.results[0].geometry.location;
      } else {
        throw new Error(
          "Error fetching location with fallback address: " + data.status
        );
      }
    }
  } catch (error) {
    console.error("Error in geocoding process", error);
    throw error;
  }
};

const containerStyle = {
  marginTop: "10px",
  width: "300px",
  height: "302px",
  borderRadius: "15px",
  overflow: "none",
};

const Map = ({ address, city, state }) => {
  const [center, setCenter] = React.useState({
    lat: 4.570868,
    lng: -74.297333,
  });

  const mapOptions = {
    streetViewControl: false, // Desactiva el control de Street View
    scaleControl: false, // Desactiva el control de escala
    mapTypeControl: false, // Desactiva el control para cambiar el tipo de mapa
    panControl: false, // Desactiva el control de paneo
    zoomControl: false, // Desactiva los controles de zoom
    fullscreenControl: false, // Desactiva el botón de pantalla completa
    gestureHandling: "none", // Desactiva el gesto de desplazamiento
  };

  useEffect(() => {
    if (address) {
      geocodeAddress(address, city, state, apiKey)
        .then(setCenter)
        .catch(console.error);
    }
  }, [address]);
  return (
    <LoadScript googleMapsApiKey={apiKey}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={17}
        options={mapOptions}
      >
        <Marker position={center} />
      </GoogleMap>
    </LoadScript>
  );
};

export default React.memo(Map);
